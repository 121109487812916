import React from "react";
import sortiment from "../assets/images/sortiment_2.jpg";

const ProductCatalogSection = () => {
  return (
    <section className="max-w-allsafe-lg mx-[auto] px-4 sm:px-11 md:px-4 md:flex md:items-flex-start md:gap-x-6 lg:gap-x-16 lg:px-11 2xl:px-4">
      <div className="md:basis-2/4 h-[299px]">
        <img
          className="w-full h-full object-cover"
          src={sortiment}
          alt="sortiment"
        />
      </div>
      <div className="md:basis-2/4">
        <h4 className="text-allsafe-dark-blue-sencodary text-xl text-left leading-allsafe-leading-xl leading-8 font-Roboto-md mt-6 mb-5  md:text-2xl md:mt-0 2xl:text-4xl 2xl:leading-allsafe-leading-4xl">
          Entdecken Sie unser Sortiment
        </h4>
        <p className="text-allsafe-main-text text-base leading-6 text-left leading-allsafe-leading-md font-Roboto-light mb-4 md:text-lg md:leading-8 2xl:text-2xl 2xl:leading-allsafe-leading-2xl">
          Vom Zurrpunkt über Gurte, Netze und Schienen bis hin zu
          Sperrelementen, bieten wir alles für den sicheren Transport wertvoller
          Güter auf dem LKW oder im Transporter. Unsere Produkte und Systeme
          sind dabei gewichtsoptimiert mit gesetzeskonformer Sicherheit und
          sorgen für zusätzliche Stabilität des Fahrzeugaufbaus.
        </p>
        <a
          className="text-allsafe-active-blue hover:text-allsafe-hover-blue text-lg text-left leading-allsafe-leading-x-sm font-Roboto-light underline lg:text-2xl lg:leading-allsafe-leading-2xl"
          href="https://www.allsafe24.com/produkte-und-systeme">
          Jetzt ansehen
        </a>
      </div>
    </section>
  );
};

export default ProductCatalogSection;

import React from "react";
import logo from "../assets/images/logo.png";
import sortiment from "../assets/images/sortiment_1.jpg";

const Header = () => {
  return (
    <header>
      <div className="w-[66px] h-[60px] my-5 ml-5">
        <img className="w-full h-full" src={logo} alt="logo" />
      </div>
      <div className="relative">
        <div className="h-[287px] md:h-[297px] lg:h-[490px]">
          <img
            className="w-full h-full object-cover md:object-[50%_43%]"
            src={sortiment}
            alt="sortiment"
          />
        </div>
        <div className="bg-[#6D7F8D] h-full w-full absolute top-0 mix-blend-multiply"/>
        <div className="flex flex-col items-center w-full absolute top-[39%] 2xl:right-[13px]">
          <h3 className="m-0 text-allsafe-white text-2xl leading-8 md:text-4xl md:leading-allsafe-leading-4xl font-Roboto-md 2xl:text-[65px] 2xl:leading-allsafe-leading-7xl">
            Ladungssicherung
          </h3>
          <p className="m-0 text-allsafe-white text-lg leading-6 md:text-4xl leading-allsafe-leading-4xl font-Roboto-md 2xl:text-[45px]">
            von allsafe
          </p>
        </div>
      </div>
    </header>
  );
};

export default Header;

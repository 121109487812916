import React from "react";

const Logo = () => {
  return (
    <svg
      viewBox="0 0 263 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-full h-full">
      <path
        d="M0.802734 22.3945V56.9392H35.3243V42.1345H15.5966V22.3945H0.802734Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M23 0.189453V14.9966H42.73V34.7318H57.5239V0.189453H23Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M96.2695 48.5312H106.026V78.1015H96.2695V48.5312Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M111.318 48.5312H121.073V78.1015H111.318V48.5312Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M202.762 57.2672V55.6755C202.762 49.951 206.478 48.2012 214.714 48.2012H225.813V53.5873H217.353C213.443 53.5873 212.524 54.1599 212.524 56.2825V57.2672H225.813V62.6562H212.524V78.0912H202.762V57.2672Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M135.409 71.2788C135.409 72.4248 135.922 73.1899 141.46 73.1899H144.916C149.536 73.1899 150.701 72.9117 150.701 71.6398C150.701 70.419 149.943 69.9236 146.489 69.9236H137.342C128.244 69.8801 125.553 68.2878 125.553 62.5738C125.553 57.3854 130.737 56.6094 137.443 56.6094H144.252C157.005 56.6094 159.241 58.0417 159.343 62.9438H149.589C149.076 61.7145 148.161 61.5115 142.318 61.5115C136.681 61.5115 135.618 61.6284 135.618 63.0687C135.618 63.9625 136.373 64.298 141.46 64.298H148.214C159.901 64.298 160.768 67.4314 160.768 71.1539C160.768 76.6673 158.023 78.1001 146.896 78.1001H137.647C128.658 78.1001 125.348 76.4222 125.348 71.2788H135.409Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M228.518 66.2095C228.518 58.3278 233.388 56.6191 240.505 56.6191H247.307C257.677 56.6191 261.946 58.3718 261.946 65.5944V69.1058H238.259V70.3767C238.259 72.5751 240.192 73.1898 244.258 73.1898H247.255C252.54 73.1898 252.795 72.0537 252.795 70.8329H261.946V71.7253C261.946 76.9534 256.153 78.0995 249.092 78.0995H241.776C235.317 78.0995 228.518 77.1124 228.518 68.212V66.2095ZM252.495 64.4483C252.495 61.9665 251.066 61.5104 245.375 61.5104C239.023 61.5104 238.259 61.9665 238.259 64.4483H252.495Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M188.371 56.9375H177.038C168.807 56.9375 165.353 57.881 165.353 63.5123H174.801C174.903 62.0402 175.72 61.8372 178.565 61.8372H184.308C188.118 61.8372 188.871 62.7297 188.871 64.7776H178.718C169.266 64.7776 164.377 65.1059 164.377 70.7784V72.4979C164.377 77.3995 168.188 78.1396 174.131 78.0899L198.63 78.0975V64.4047C198.63 59.5135 195.079 56.9375 188.371 56.9375ZM184.094 73.1878H178.815C175.003 73.1878 173.848 72.8594 173.848 71.1848C173.855 69.586 175.006 69.1899 178.816 69.1899H184.094C187.907 69.1899 189.069 69.5519 189.069 71.1848C189.069 72.8339 187.902 73.1878 184.094 73.1878Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M81.5257 56.9375H70.1932C61.9627 56.9375 58.5085 57.881 58.5085 63.5123H67.9607C68.0577 62.0402 68.8749 61.8372 71.7248 61.8372H77.4626C81.2731 61.8372 82.0287 62.7297 82.0287 64.7776H71.8753C62.4212 64.7776 57.5371 65.1059 57.5371 70.7784V72.4979C57.5371 77.3995 61.3424 78.1396 67.2912 78.0899L91.7851 78.0975V64.4047C91.7851 59.5135 88.2349 56.9375 81.5257 56.9375ZM77.2487 73.1878H71.9723C68.159 73.1878 67.0021 72.8594 67.0021 71.1848C67.0049 69.586 68.1609 69.1899 71.977 69.1899H77.2567C81.0672 69.1899 82.2289 69.5519 82.2289 71.1848C82.2289 72.8339 81.062 73.1878 77.2487 73.1878Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M68.8279 108.409V102.17C68.8279 100.845 68.3457 99.7959 66.9002 99.7959C65.6685 99.7959 64.4548 100.882 64.4548 103.219V108.409H63.3305V102.17C63.3305 100.845 62.8484 99.7959 61.4028 99.7959C60.1711 99.7959 58.9579 100.882 58.9579 103.219V108.409H57.8332V101.232C57.8332 100.514 57.8332 99.7779 57.7617 99.0237H58.8505V100.606L58.886 100.624C59.2432 99.9805 59.7789 98.8027 61.7241 98.8027C62.5276 98.8027 63.8482 99.1519 64.1335 100.79H64.169C64.5797 99.6857 65.6505 98.8027 67.0786 98.8027C68.9169 98.8027 69.9522 100.128 69.9522 101.949V108.409H68.8279Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M77.7361 103.679H77.4153C75.4696 103.679 73.1492 103.882 73.1492 105.943C73.1492 107.177 74.0061 107.636 75.0414 107.636C77.6827 107.636 77.7361 105.262 77.7361 104.25V103.679ZM77.8436 106.9H77.8081C77.3079 108.023 76.0408 108.63 74.9165 108.63C72.3287 108.63 71.918 106.827 71.918 105.98C71.918 102.833 75.1663 102.686 77.5223 102.686H77.7361V102.207C77.7361 100.606 77.183 99.7959 75.666 99.7959C74.7201 99.7959 73.8277 100.017 72.9888 100.569V99.4467C73.6848 99.097 74.8625 98.8027 75.666 98.8027C77.915 98.8027 78.8609 99.8518 78.8609 102.299V106.44C78.8609 107.195 78.8609 107.765 78.9503 108.409H77.8436V106.9Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M84.86 107.635C86.8592 107.635 87.5552 105.427 87.5552 103.716C87.5552 102.004 86.8592 99.7948 84.86 99.7948C82.7184 99.7948 82.1653 101.893 82.1653 103.716C82.1653 105.537 82.7184 107.635 84.86 107.635ZM88.68 108.408H87.5552V106.918H87.5197C86.9127 108.114 85.9848 108.629 84.6816 108.629C82.1833 108.629 80.9336 106.495 80.9336 103.716C80.9336 100.862 82.0044 98.8016 84.6816 98.8016C86.4664 98.8016 87.3589 100.145 87.5197 100.605H87.5552V94.6055H88.68V108.408Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M96.8539 103.054C96.8539 101.416 96.2113 99.7959 94.5339 99.7959C92.874 99.7959 91.8567 101.508 91.8567 103.054H96.8539ZM97.407 108.115C96.622 108.447 95.6047 108.63 94.7658 108.63C91.7493 108.63 90.625 106.533 90.625 103.717C90.625 100.845 92.16 98.8027 94.4625 98.8027C97.0323 98.8027 98.0856 100.937 98.0856 103.459V104.048H91.8567C91.8567 106.035 92.8915 107.636 94.8552 107.636C95.6762 107.636 96.8719 107.286 97.407 106.937V108.115Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M105.033 99.0221H106.157V108.408H105.033V99.0221ZM106.157 96.519H105.033V94.9727H106.157V96.519Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M108.923 101.232C108.923 100.514 108.923 99.7779 108.852 99.0237H109.94V100.698H109.976C110.351 99.8518 111.029 98.8027 112.921 98.8027C115.169 98.8027 116.026 100.348 116.026 102.41V108.409H114.902V102.704C114.902 100.937 114.295 99.7959 112.742 99.7959C110.69 99.7959 110.047 101.655 110.047 103.219V108.409H108.923V101.232Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M126.971 107.415C129.005 107.415 129.737 105.428 129.737 103.717C129.737 101.453 129.094 99.7959 127.042 99.7959C124.9 99.7959 124.347 101.894 124.347 103.717C124.347 105.557 125.061 107.415 126.971 107.415ZM130.862 107.636C130.862 110.25 129.719 112.385 126.595 112.385C125.4 112.385 124.311 112.035 123.847 111.888L123.936 110.728C124.633 111.096 125.632 111.391 126.613 111.391C129.505 111.391 129.755 109.219 129.755 106.569H129.719C129.112 107.931 128.113 108.409 126.953 108.409C124.043 108.409 123.115 105.796 123.115 103.717C123.115 100.863 124.186 98.8027 126.863 98.8027C128.077 98.8027 128.862 98.9679 129.701 100.091H129.737V99.0237H130.862V107.636Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M139.037 103.054C139.037 101.416 138.394 99.7959 136.717 99.7959C135.057 99.7959 134.04 101.508 134.04 103.054H139.037ZM139.591 108.115C138.806 108.447 137.788 108.63 136.949 108.63C133.933 108.63 132.809 106.533 132.809 103.717C132.809 100.845 134.343 98.8027 136.646 98.8027C139.216 98.8027 140.269 100.937 140.269 103.459V104.048H134.04C134.04 106.035 135.075 107.636 137.038 107.636C137.86 107.636 139.055 107.286 139.591 106.937V108.115Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M142.325 101.121C142.325 100.091 142.325 99.7041 142.254 99.0237H143.378V100.827H143.414C143.824 99.778 144.592 98.8027 145.752 98.8027C146.02 98.8027 146.341 98.8576 146.538 98.913V100.128C146.305 100.054 146.002 100.017 145.716 100.017C143.932 100.017 143.45 102.079 143.45 103.772V108.409H142.325V101.121Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M159.193 108.409V102.17C159.193 100.845 158.711 99.7959 157.265 99.7959C156.034 99.7959 154.82 100.882 154.82 103.219V108.409H153.696V102.17C153.696 100.845 153.214 99.7959 151.768 99.7959C150.536 99.7959 149.323 100.882 149.323 103.219V108.409H148.198V101.232C148.198 100.514 148.198 99.7779 148.127 99.0237H149.216V100.606L149.251 100.624C149.608 99.9805 150.144 98.8027 152.089 98.8027C152.892 98.8027 154.213 99.1519 154.499 100.79H154.534C154.945 99.6857 156.016 98.8027 157.443 98.8027C159.282 98.8027 160.317 100.128 160.317 101.949V108.409H159.193Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M168.104 103.679H167.782C165.837 103.679 163.516 103.882 163.516 105.943C163.516 107.177 164.373 107.636 165.409 107.636C168.05 107.636 168.104 105.262 168.104 104.25V103.679ZM168.211 106.9H168.175C167.676 108.023 166.408 108.63 165.284 108.63C162.695 108.63 162.285 106.827 162.285 105.98C162.285 102.833 165.534 102.686 167.889 102.686H168.104V102.207C168.104 100.606 167.55 99.7959 166.033 99.7959C165.087 99.7959 164.195 100.017 163.356 100.569V99.4467C164.052 99.097 165.23 98.8027 166.033 98.8027C168.282 98.8027 169.228 99.8518 169.228 102.299V106.44C169.228 107.195 169.228 107.765 169.317 108.409H168.211V106.9Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M171.815 101.232C171.815 100.514 171.815 99.7779 171.744 99.0237H172.833V100.698H172.868C173.243 99.8518 173.922 98.8027 175.813 98.8027C178.062 98.8027 178.919 100.348 178.919 102.41V108.409H177.795V102.704C177.795 100.937 177.188 99.7959 175.635 99.7959C173.582 99.7959 172.94 101.655 172.94 103.219V108.409H171.815V101.232Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M184.188 106.843H184.206L186.83 99.0215H188.007L184.366 109.806C183.938 111.094 183.295 112.383 182.029 112.383C181.618 112.383 181.189 112.327 180.815 112.235L180.922 111.242C181.136 111.315 181.35 111.388 181.76 111.388C182.724 111.388 183.046 110.56 183.403 109.346L183.635 108.518L180.404 99.0215H181.636L184.188 106.843Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M193.683 107.048C194.362 107.397 195.183 107.636 196.057 107.636C197.128 107.636 198.074 107.029 198.074 105.962C198.074 103.735 193.701 104.084 193.701 101.361C193.701 99.5016 195.165 98.8027 196.664 98.8027C197.146 98.8027 198.11 98.913 198.913 99.2258L198.805 100.238C198.217 99.962 197.414 99.7959 196.789 99.7959C195.629 99.7959 194.825 100.165 194.825 101.361C194.825 103.109 199.305 102.889 199.305 105.962C199.305 107.949 197.503 108.63 196.128 108.63C195.254 108.63 194.379 108.52 193.576 108.189L193.683 107.048Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M206.803 103.054C206.803 101.416 206.16 99.7959 204.483 99.7959C202.823 99.7959 201.805 101.508 201.805 103.054H206.803ZM207.356 108.115C206.571 108.447 205.553 108.63 204.715 108.63C201.698 108.63 200.574 106.533 200.574 103.717C200.574 100.845 202.109 98.8027 204.411 98.8027C206.982 98.8027 208.034 100.937 208.034 103.459V104.048H201.805C201.805 106.035 202.841 107.636 204.804 107.636C205.625 107.636 206.821 107.286 207.356 106.937V108.115Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M210.086 99.0221H211.211V108.408H210.086V99.0221ZM211.211 96.519H210.086V94.9727H211.211V96.519Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M217.908 100.017H215.837V106.091C215.837 106.938 216.141 107.637 217.051 107.637C217.479 107.637 217.765 107.545 218.086 107.416L218.158 108.373C217.89 108.483 217.337 108.631 216.784 108.631C214.784 108.631 214.713 107.213 214.713 105.502V100.017H212.928V99.0242H214.713V96.76L215.837 96.3555V99.0242H217.908V100.017Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M228.332 95.5625H229.438V108.408H228.206V96.9981L226.19 98.8203L225.512 97.9918L228.332 95.5625Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M240.74 99.7215C240.74 98.1387 239.901 96.538 238.063 96.538C236.242 96.538 235.26 97.8444 235.26 99.5748C235.26 101.451 236.171 102.574 237.902 102.574C239.687 102.574 240.74 101.415 240.74 99.7215ZM242.096 101.525C242.096 105.409 240.686 108.537 236.474 108.537C235.796 108.537 235.136 108.408 234.475 108.243L234.582 107.028C235.1 107.267 235.921 107.433 236.635 107.433C239.865 107.488 240.865 104.709 240.865 102.096H240.829C240.187 103.182 239.062 103.678 237.902 103.678C236.26 103.678 234.029 102.85 234.029 99.6666C234.029 97.1635 235.546 95.4336 238.099 95.4336C241.4 95.4336 242.096 98.5069 242.096 101.525Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M245.327 104.249C245.327 105.832 246.166 107.433 248.004 107.433C249.825 107.433 250.807 106.126 250.807 104.396C250.807 102.519 249.896 101.396 248.164 101.396C246.38 101.396 245.327 102.556 245.327 104.249ZM243.971 102.446C243.971 98.5622 245.381 95.4336 249.593 95.4336C250.271 95.4336 250.932 95.5623 251.592 95.7279L251.485 96.9426C250.967 96.7031 250.146 96.538 249.433 96.538C246.201 96.4822 245.202 99.2611 245.202 101.875H245.238C245.88 100.789 247.005 100.292 248.164 100.292C249.807 100.292 252.038 101.12 252.038 104.304C252.038 106.807 250.521 108.537 247.969 108.537C244.667 108.537 243.971 105.464 243.971 102.446Z"
        fill="white"
        className="w-full h-full"></path>
      <path
        d="M259.181 96.8325H259.146L254.363 104.341H259.181V96.8325ZM253.238 104.194L258.843 95.5625H260.414V104.341H262.251V105.446H260.414V108.408H259.181V105.446H253.238V104.194Z"
        fill="white"
        className="w-full h-full"></path>
    </svg>
  );
};

export default Logo;

import React from "react";
import albertTip from "../assets/images/alberts_Tipp.png";
import albertVideo from "../assets/video/vorstellung.mp4";

const AlbertTippSection = () => {
  return (
    <section className="max-w-[1043px] mx-4 mt-36 border-[3px] border-solid border-allsafe-active-blue sm:mx-11 lg:mt-52 lg:mx-[auto]">
      <div className="relative pt-20 pb-8 md:pt-8">
        <div className="bg-allsafe-white flex items-center absolute top-[-76px] left-[30px] pr-4 max-w-[max-content]">
          <div className="w-[165px] h-[147px] flex justify-end relative right-[10px]">
            <img className="w-[80%] h-[85%]" src={albertTip} alt="albert" />
          </div>
          <span className="text-lg leading-8 text-allsafe-dark-blue-sencodary lg:text-4xl text-left lg:leading-allsafe-leading-4xl font-Roboto-light">
            Alberts Tipp
          </span>
        </div>
        <div className="md:flex items-center lg:gap-x-16">
          <div className="md:basis-2/4 px-5 md:px-12 lg:pr-0">
            <p className="text-lg leading-8 2xl:text-2xl 2xl:leading-allsafe-leading-2xl text-allsafe-main-text font-Roboto-light mb-6 lg:mb-0">
              Durch ausgesuchte deutsche und europäische Lieferanten garantieren
              wir Ihnen 100% Liefersicherheit und durch die auftragsbezogene
              Produktion versenden wir Ihre Ware innerhalb von 24 Stunden.
            </p>
          </div>
          <div className="md:basis-2/4 h-[279px] lg:h-[260px] px-8 lg:pl-0">
            <video className="w-full h-full object-fill" controls>
              <source src={albertVideo} type="video/mp4" />
              <track src="" kind="captions" label="German" />
            </video>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AlbertTippSection;

import React from "react";
import Contact from "./Contact";

const ContactSection = () => {
  return (
    <section className="mt-36 lg:mt-24 bg-allsafe-grey-100 md:pt-11 pb-8 2xl:pt-16 2xl:pb-24">
      <Contact />
    </section>
  );
};

export default ContactSection;

import React from "react";
import Heading from "./Heading";
import teaserSchienen from "../assets/images/teaser_Schienen.jpg";
import teaserperrelemente from "../assets/images/teaser_Sperrelemente.jpg";
import suissFlag from "../assets/images/swiss-flag.jpg";
import checkIcon from "../assets/icons/check-icon.svg";

const paragraph =
  "allsafe steht für ein komplettes, ausgewähltes Sortiment in Sachen Ladungssicherung. Vom Zurrpunkt über Gurte, Netze und Schienen bis hin zu Sperrelementen, bieten wir alles für den sicheren Transport wertvoller Güter auf dem LKW oder im Transporter.";

const GermanSecuritySolutionSection = () => {
  return (
    <section className="max-w-allsafe-lg mx-[auto] px-4 mt-14 md:flex md:gap-x-6 sm:px-11 md:px-4 lg:px-11 2xl:gap-x-16 2xl:px-4 2xl:mt-24">
      <div className="md:basis-2/4">
        <div className="mb-8 ">
          <Heading title="Sicherheit made in Germany!" paragraph={paragraph} />
        </div>
        <div className="flex gap-x-8">
          <div className="h-[116px] basis-[328px] md:h-[175px] 2xl:basis-[508px]">
            <img
              className="w-full h-full object-cover"
              src={teaserperrelemente}
              alt="teaserperrelemente"
            />
          </div>
          <div className="h-[116px] md:basis-[158px] md:h-[175px] 2xl:basis-[237px]">
            <img
              className="w-full h-full object-cover"
              src={teaserSchienen}
              alt="teaserSchienen"
            />
          </div>
        </div>
      </div>
      <div className="md:basis-2/4">
        <h5 className="text-lg text-allsafe-main-text font-Roboto-md leading-allsafe-leading-md my-11 text-start 2xl:text-2xl 2xl:leading-allsafe-leading-2xl md:mt-12 2xl:mt-20 2xl:mb-8 2xl:relative top-[8px]">
          allsafe – die richtige Entscheidung, wenn es um qualitativ hochwertige
          und verantwortungsbewusste Ladungssicherung geht.
        </h5>
        <div className="bg-[#F6F7F8] pt-10 md:pt-0">
          <div className="md:flex justify-between items-center md:h-[223px] 2xl:h-[232px]">
            <div className="md:basis-[63%] flex flex-col justify-end md:pl-6 pl-8 ">
              <h4 className="text-allsafe-main-text text-lg lg:text-2xl leading-allsafe-leading-lg font-Roboto-md text-start lg:mb-4">
                AUF EINEN BLICK
              </h4>
              <ul className="pb-11 2xl:pb-0">
                <li className="text-base leading-6 text-allsafe-main-text text-start font-Roboto-light list-none flex gap-x-3 lg:items-center 2xl:text-2xl 2xl:leading-10">
                  <div className="w-[21px] h-[14px]">
                    <img src={checkIcon} alt="check-icon" />
                  </div>
                  <p>Schnelle Lieferung</p>
                </li>
                <li className="text-base leading-6 text-allsafe-main-text text-start font-Roboto-light list-none flex gap-x-3 lg:items-center 2xl:text-2xl 2xl:leading-10">
                  <div className="w-[21px] h-[14px]">
                    <img src={checkIcon} alt="check-icon" />
                  </div>
                  <p>Hersteller aus Deutschland</p>
                </li>
                <li className="text-base leading-6 text-allsafe-main-text text-start font-Roboto-light list-none flex gap-x-3 lg:items-center 2xl:text-2xl 2xl:leading-10">
                  <div className="w-[21px] h-[14px]">
                    <img src={checkIcon} alt="check-icon" />
                  </div>
                  <p>
                    Betreuung vor Ort in der{" "}
                    <span className="text-allsafe-main-text font-Roboto-normal">
                      Schweiz
                    </span>
                  </p>
                </li>
              </ul>
            </div>
            <div className="md:w-3/6 h-[197px] md:h-full lg:w-[275px]">
              <img
                className="w-full h-full object-cover object-top md:object-left-top 2xl:object-left"
                src={suissFlag}
                alt="swiss flag"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GermanSecuritySolutionSection;

import React from "react";
import michael_Staubli from "../assets/images/michael_Staubli.png";
import callIcon from "../assets/icons/call-icon.svg";
import emailIcon from "../assets/icons/email-icon.svg";

const Contact = () => {
  return (
    <div className="px-4 sm:px-11 md:px-4 relative md:pb-0 md:px-0">
      <div className="md:max-w-[71%] lg:max-w-[60%] 2xl:max-w-[910px] mx-[auto] relative">
        <div className="flex items-center justify-between">
          <h2 className="max-w-[179px] pt-8 text-xl text-allsafe-dark-blue-sencodary font-Roboto-md sm:text-3xl leading-6 sm:leading-allsafe-leading-4xl sm:max-w-[270px] 2xl:pt-0 2xl:max-w-[464px] 2xl:text-5xl 2xl:leading-allsafe-leading-6xl">
            Ihr Ansprechpartner in der Schweiz:
          </h2>
          <div className="absolute top-[-84px] flex flex-col items-center md:top-[-11px] 2xl:top-[-15px] left-[70%] sm:left-[69%] md:left-[439px] lg:max-w-[309px] 2xl:items-end">
            <div className="max-w-[170px] h-[158px] 2xl:w-[309px] 2xl:h-[287px] 2xl:max-w-[initial] 2xl:flex 2xl:justify-end">
              <img
                className="w-[90%] h-[94%] 2xl:w-[80%] 2xl:h-[80%] object-contain"
                src={michael_Staubli}
                alt="michael_Staubli"
              />
            </div>
            <p className="font-Roboto-light text-allsafe-main-text text-base leading-6 text-center max-w-[180px] 2xl:text-[21px] 2xl:relative 2xl:top-[-40px] left-[8px] 2xl:max-w-[80%]">
              Michael Staubli Außendienst Schweiz
            </p>
          </div>
        </div>
        <div className="mt-4 2xl:mt-5">
          <div className="mb-3 2xl:mb-5">
            <div className="flex gap-x-8 items-center">
              <div className="w-[19px] h-[19px] 2xl:w-[35px] 2xl:h-[35px]">
                <img src={callIcon} alt="call icon" className="w-full h-full" />
              </div>
              <a
                className="text-base leading-6 text-allsafe-dark-blue-sencodary font-Roboto-light underline cursor-pointer 2xl:text-2xl 2xl:leading-8"
                href="tel:+41(0)78616099">
                +41 78 616 0909
              </a>
            </div>
          </div>
          <div className="flex gap-x-8 items-center">
            <div className="w-[19px] h-[19px] 2xl:w-[35px] 2xl:h-[26px]">
              <img src={emailIcon} alt="email icon" className="w-full h-full" />
            </div>
            <a
              className="text-base leading-6 text-allsafe-dark-blue-sencodary font-Roboto-light underline cursor-pointer 2xl:text-2xl 2xl:leading-8"
              href="mailto:michael.staubli@allsafe-group.com">
              michael.staubli@allsafe-group.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

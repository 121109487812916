import React from "react";
import Header from "./components/Header";
import ProductRangeSection from "./components/ProductRangeSection";
import ProductCatalogSection from "./components/ProductCatalogSection";
import GermanSecuritySolutionSection from "./components/GermanSecuritySolutionSection";
import Footer from "./components/Footer";
import AlbertTippSection from "./components/AlbertTippSection";
import ContactSection from "./components/ContactSection";

function App() {
  return (
    <div>
      <Header />
      <GermanSecuritySolutionSection />
      <ContactSection />
      <ProductRangeSection />
      <ProductCatalogSection />
      <AlbertTippSection />
      <Footer />
    </div>
  );
}

export default App;

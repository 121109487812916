import React from "react";
import Card from "./Card";
import Heading from "./Heading";
import pat from "../assets/images/Pat-1000.jpg";
import sam from "../assets/images/SAM.jpg";

const paragraph =
  "Unsere Produkte gelten als besonders komfortabel in der Anwendung, sind robust und äußerst langlebig. In unserem Sortiment finden Sie alles von der Sperrstange, über diverse Airline-Schienen bis hin zu Zurrgurten und Netzen. Rufen Sie uns an - gemeinsam finden wir bestimmt die richtige Lösung, denn wir bieten Ihnen kundenindividuelle Produkte an.";

const ProductRangeSection = () => {
  return (
    <section className="max-w-allsafe-lg mx-[auto] px-4 sm:px-11 md:px-4 lg:px-11 mt-20 mb-16 lg:mb-28 2xl:px-4">
      <Heading title="Unser Sortiment" paragraph={paragraph} />
      <div className="md:flex md:gap-x-6 lg:gap-x-16 mt-20 lg:mt-14">
        <div className="mb-20 lg:mb-0">
          <Card
            image={pat}
            title="Das stärkste Klemmbrett am Markt: unsere PAT 1000"
            href="https://www.allsafe24.com/produkte-und-systeme/produkte/sperrelemente/spannbretter"
            description="Unsere PAT: einmalig – mit Patent! Dieses Spannbrett gewährleistet höchste Sicherheit und bringt Ihre Ladung sicher ans Ziel."
            label="Jetzt informieren"
          />
        </div>
        <Card
          image={sam}
          title="Die beliebteste Klemmstange im Kofferfahrzeug: unsere SAM 55 Profi"
          href="https://www.allsafe24.com/produkte-und-systeme/produkte/sperrelemente/klemmbalken"
          description="Keine Airline-Schienen verbaut? Kein Problem unsere Klemmbalken mit Gummifüßen sichern Ihre Ladung zuverlässig."
          label="Jetzt informieren"
        />
      </div>
    </section>
  );
};

export default ProductRangeSection;

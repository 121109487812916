import React from "react";

const Heading = ({ title, paragraph }) => {
  return (
    <div>
      <h2 className="text-allsafe-dark-blue-primary text-xl leading-6 md:text-2xl md:leading-8 text-start mb-4 font-Roboto-md  2xl:text-[52px] 2xl:leading-allsafe-leading-5xl 2xl:mb-8">
        {title}
      </h2>
      <p className="text-allsafe-main-text text-base leading-6 md:text-lg md:leading-allsafe-leading-md text-start font-Roboto-light 2xl:text-2xl 2xl:max-w-allsafe-md md:max-w-[750px]">
        {paragraph}
      </p>
    </div>
  );
};

export default Heading;

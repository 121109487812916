import React from "react";

const Card = ({ title, description, label, href, image }) => {
  return (
    <div>
      <div className="h-[252px] mb-6 md:h-[379px]">
        <img className="w-full h-full" src={image} alt="card" />
      </div>
      <h3 className="text-allsafe-dark-blue-sencodary text-xl leading-8 text-left md:text-2xl md:leading-allsafe-leading-md font-Roboto-md my-6 2xl:text-4xl 2xl:allsafe-leading-4xl md:max-w-[400px] 2xl:max-w-[637px]">
        {title}
      </h3>
      <p className="text-allsafe-main-text text-base md:text-lg 2xl:text-2xl text-left leading-allsafe-leading-md font-Roboto-light mb-6 2xl:leading-allsafe-leading-2xl">
        {description}
      </p>
      <button className="bg-allsafe-active-blue hover:bg-allsafe-hover-blue rounded text-lg text-allsafe-white leading-allsafe-leading-x-sm Roboto-normal pt-4 pb-3 pl-6 pr-8 cursor-pointer shadow-[0px_4px_0px_0px_#2f579c] hover:shadow-[0px_4px_0px_0px_#1d3966]">
        <a href={href}>{label}</a>
      </button>
    </div>
  );
};

export default Card;

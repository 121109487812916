import React from "react";
import Contact from "./Contact";
import Logo from "./Logo";

const Footer = () => {
  return (
    <footer className="mt-32">
      <div className="bg-[#F6F7F8] md:pt-11 pb-8 2xl:pt-16 2xl:pb-24">
        <Contact />
      </div>
      <div className="bg-[#434d55] px-3.5 pt-16 pb-6 lg:pt-20">
        <div className="max-w-allsafe-lg mx-[auto]">
          <div className="w-[169.594px] h-[72px]">
            <Logo />
          </div>
          <hr className=" text-allsafe-grey-200 my-6 border" />
          <div className="flex items-center justify-between gap-x-8 gap-y-10 flex-wrap md:gap-y-0">
            <ul className="p-0 m-0 flex flex-wrap gap-x-4 max-w-[max-content]">
              <li>
                <a
                  className="text-allsafe-grey-100 underline decoration-allsafe-grey-200 text-base leading-allsafe-leading-x-sm font-Roboto-light decoration-2"
                  href="https://www.allsafe24.com/datenschutzerklaerung">
                  Datenschutzerklärung
                </a>
              </li>
              <li>
                <a
                  className="text-allsafe-grey-100 underline decoration-allsafe-grey-200 text-base leading-allsafe-leading-x-sm font-Roboto-light decoration-2"
                  href="https://www.allsafe24.com/datenschutzerklaerung#cookie-settings">
                  Cookie-Einstellungen
                </a>
              </li>
              <li>
                <a
                  className="text-allsafe-grey-100 underline decoration-allsafe-grey-200 text-base leading-allsafe-leading-x-sm font-Roboto-light decoration-2"
                  href="https://www.allsafe24.com/impressum">
                  Impressum
                </a>
              </li>
              <li>
                <a
                  className="text-allsafe-grey-100 underline decoration-allsafe-grey-200 text-base leading-allsafe-leading-x-sm font-Roboto-light decoration-2"
                  href="https://www.allsafe24.com/avb">
                  AVB
                </a>
              </li>
            </ul>
            <p className="text-[#f0f1f4] text-base leading-allsafe-leading-x-sm font-Roboto-light opacity-75">
              © 2023 allsafe GmbH & Co. KG
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
